import API from "../api/manager";
import config from "../api/config";

const getProfiles = (query) => {
  return API({
    method: "GET",
    url: config.urls.user.index + query,
  });
};

const getInvitedProfiles = (query) => {
  return API({
    method: "GET",
    url: config.urls.user.invitedMediator + query,
  });
};

const getProfile = (id) => {
  return API({
    method: "GET",
    url: config.urls.user.index + `/${id}`,
  });
};

const setProfile = (id, data) => {
  return API({
    method: "PUT",
    url: config.urls.user.update + `/${id}`,
    data,
  });
};

const setConfig = (data) => {
  return API({
    method: "POST",
    url: config.urls.systemConfig,
    data,
  });
};

const getConfig = () => {
  return API(
    {
      method: "GET",
      url: config.urls.systemConfig,
    },
    false,
  );
};

const caseStages = (data) => {
  return API({
    method: "POST",
    url: config.urls.case.caseStages,
    data,
  });
};

const documentTypes = (data) => {
  return API({
    method: "POST",
    url: config.urls.case.documentTypes,
    data,
  });
};

const deleteCaseStages = (id) => {
  return API({
    method: "DELETE",
    url: `${config.urls.case.deleteCaseStages}/${id}`,
  });
};

const deleteDocumentTypes = (id) => {
  return API({
    method: "DELETE",
    url: `${config.urls.case.deleteDocumentTypes}/${id}`,
  });
};

const getCaseStages = () => {
  return API({
    method: "GET",
    url: config.urls.case.getCaseStages,
  });
};

const getDocumentTypes = () => {
  return API({
    method: "GET",
    url: config.urls.case.getDocumentTypes,
  });
};

const updateCaseStages = (id, data) => {
  return API({
    method: "PUT",
    url: config.urls.case.caseStages + `/${id}`,
    data,
  });
};

const updateRecordInMIS = () => {
  return API({
    method: "POST",
    url: config.urls.case.updateRecordInMIS,
  });
};

const getConfigAuth = () => {
  return API({
    method: "GET",
    url: config.urls.systemConfig,
  });
};

export default {
  getConfig,
  setConfig,
  getProfiles,
  getProfile,
  setProfile,
  getInvitedProfiles,
  caseStages,
  documentTypes,
  deleteCaseStages,
  deleteDocumentTypes,
  getCaseStages,
  getDocumentTypes,
  updateCaseStages,
  updateRecordInMIS,
  getConfigAuth,
};
