import React, { Fragment, useEffect, useState } from "react";
import ActionBar from "./ActionBar";
import _ from "lodash";
import { navigate, useLocation } from "@reach/router";
import styled from "styled-components";
import themes from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import TranscriptEditor from "../../transcripts/TranscriptEditor/index";

const TranscriptEdit = (id) => {
  const history = useLocation();
  const resolutionKind = history.search.replace("?caseType=", "");
  const breadcrumbs = ["Cases", _.startCase(resolutionKind), "Transcript"];
  const location = useLocation();
  const [data, setData] = useState([
    {
      id: "",
      start: "",
      end: "",
      text: "",
      speaker: "",
      confidence: "",
      count: 0,
      mediaId: "",
      transcriptionFlag: "",
      speechFinal: "",
      utteranceEnd: "",
      lastTranscriptEndTime: "",
    },
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const meetingAudioId = location?.state?.meetingAudioId;
  const [mediaUrl, setMediaUrl] = useState();
  const [discard, setDiscard] = useState([
    {
      id: "",
      start: "",
      end: "",
      text: "",
      speaker: "",
      confidence: "",
      mediaId: "",
      transcriptionFlag: "",
      speechFinal: "",
      utteranceEnd: "",
      lastTranscriptEndTime: "",
    },
  ]);
  const [refreshDetails, setRefreshDetails] = useState(true);
  const [render, setRender] = useState(false);
  const [state, setState] = useState({ state: false });

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case resolutionKind:
        navigate(`/dashboard/cases/${id?.id}?caseType=${resolutionKind}`);
        break;
      case "cases":
        navigate(`/dashboard/cases?caseType=${resolutionKind}`);
        break;
      default:
        break;
    }
  };

  const updateTranscriptionData = async (data) => {
    try {
      setLoader({ state: true, message: "Adding Transcript..." });
      const payload = {
        editedTranscriptionData: data,
      };
      const res = await CaseService.updateAudioTranscriptData(
        payload,
        meetingAudioId,
      );
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
      setRefreshDetails(true);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  useEffect(() => {
    async function getData(meetingAudioId) {
      try {
        setLoader({ state: true, message: "Fetching Transcription..." });
        const res = await CaseService.getMeetingAudioTranscript(meetingAudioId);
        if (res) {
          setData(
            res?.transcriptData?.words?.map((el) => {
              return {
                id: el?.id,
                end: el?.end,
                confidence: el?.confidence,
                text: el?.text ? el?.text : "",
                start: el?.start,
                speaker: el?.speaker,
                mediaId: el?.mediaId,
                transcriptionFlag: el?.transcriptionFlag,
                speechFinal: el?.speechFinal,
                utteranceEnd: el?.utteranceEnd,
                lastTranscriptEndTime: el?.lastTranscriptEndTime,
              };
            }),
          );
          setDiscard(
            res?.transcriptData?.words?.map((el) => {
              return {
                id: el?.id,
                end: el?.end,
                confidence: el?.confidence,
                text: el?.text ? el?.text : "",
                start: el?.start,
                speaker: el?.speaker,
                mediaId: el?.mediaId,
                transcriptionFlag: el?.transcriptionFlag,
                speechFinal: el?.speechFinal,
                utteranceEnd: el?.utteranceEnd,
                lastTranscriptEndTime: el?.lastTranscriptEndTime,
              };
            }),
          );
          setMediaUrl(res);
          setRender(true);
        }
      } catch (error) {
        console.log("failed");
      } finally {
        setLoader({ state: false });
      }
    }
    if (refreshDetails) {
      getData(meetingAudioId);
      setRefreshDetails(false);
    }
  }, [refreshDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const discardChanges = () => {
    setData(
      discard.map((el) => {
        return {
          id: el?.id,
          end: el?.end,
          confidence: el?.confidence,
          text: el?.text,
          start: el?.start,
          speaker: el?.speaker,
          mediaId: el?.mediaId,
          transcriptionFlag: el?.transcriptionFlag,
          speechFinal: el?.speechFinal,
          utteranceEnd: el?.utteranceEnd,
          lastTranscriptEndTime: el?.lastTranscriptEndTime,
        };
      }),
    );
    setRender(true);
  };

  const actions = [
    {
      text: "Refresh",
      props: {
        onClick: () => setRefreshDetails(true),
      },
      outlined: true,
      hidden: false,
    },
    {
      text: "Discard",
      props: {
        onClick: () => discardChanges(),
      },
      hidden: data ? false : true,
    },
    {
      text: "Save (alt + s)",
      props: {
        onClick: () => updateTranscriptionData(data),
      },
      hidden: data ? false : true,
    },
  ];

  return (
    <Fragment>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick, actions }} />
      <Container>
        {/* <Heading>
            <BackArrow
              onClick={() => navigate(`${previousPath}`)}
              src={require("../../../assets/images/backArrow.svg")}
            />
            {"Transcription"}
            <PrimaryCTAButtonRefresh
              style={{ marginLeft: "550px", marginRight: "20px" }}
              onClick={() => setRefreshDetails(true)}
            >
              Refresh
            </PrimaryCTAButtonRefresh>{" "}
            {data ? (
              <PrimaryCTAButtonRefresh onClick={() => discardChanges()}>
                Discard
              </PrimaryCTAButtonRefresh>
            ) : null}
            {data ? (
              <PrimaryCTAButtonRefresh
                style={{
                  marginLeft: "20px",
                }}
                onClick={() => {
                  updateTranscriptionData(data);
                }}
              >
                Save
              </PrimaryCTAButtonRefresh>
            ) : null}
          </Heading> */}
        {data && (
          <TranscriptEditor
            {...{ meetingAudioId, data, render, setRender, state, setState }}
            mediaUrl={mediaUrl?.audioFileUrl}
            title={mediaUrl?.title}
            setData={setData}
            updateState={updateTranscriptionData}
          />
        )}
      </Container>
    </Fragment>
  );
};

export default TranscriptEdit;

export const Container = styled.div`
  padding: 4px 43px;
  display: flex;
  flex-direction: column;
`;

export const HeadingContainer = styled.span`
  margin-bottom: 31px;
  font-family: ${themes.fonts.primaryFontSemiBold};
  font-size: ${(props) => (props.size ? props.size : "13px")};
  color: ${COLORS.BTN_GREEN};
  // text-transform: uppercase;
  cursor: pointer;
  & .topSection {
    background: ${COLORS.BOX_DARKGREEN};
  }
`;
