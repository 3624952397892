import React, { useState, useEffect } from "react";
import ActionBar from "../../common/ActionBar";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { navigate } from "@reach/router";
import _ from "lodash";
import { Field, Formik } from "formik";
import { HyperLink } from "../../../styles/component/style";
import { PrimaryCTAButton } from "../../common/Buttons";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import SystemService from "../../../services/SystemService";
import { getErrorMessage } from "../../../helpers/functions";
import { FormLabel } from "@material-ui/core";
import { CustomInputField } from "../../common/FormInputs";
import { congurationEditorSchema } from "../../../helpers/validationSchema";
import labels from "../../../helpers/labels.json";
import ReactQuillEditor from "../../common/ReactQuillEditor";

const Index = () => {
  const breadcrumbs = [_.startCase(labels.configurations), labels.update_tncs];
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [systemConfig, setSystemConfig] = useState({
    mobile: "",
    tutorialUrl: "",
    faq: [],
    terminology: [],
    tnc: [],
    privacyPolicy: [],
  });

  useEffect(() => {
    async function getSystemConfig() {
      try {
        setLoader({ state: true, message: "Fetching Configurations..." });
        const response = await SystemService.getConfigAuth();
        if (response.systemConfig) {
          setSystemConfig(response.systemConfig);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    getSystemConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "configurations":
        navigate("/dashboard/configurations");
        break;
      default:
        break;
    }
  };
  function addSection(setFieldValue, sections) {
    let newSections = [...sections, { question: "", answer: "" }];
    setFieldValue("sections", newSections);
  }

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "Updating Terms and Conditions..." });
      const payload = {
        ...systemConfig,
        tnc: values.sections?.map((el) => {
          return {
            ...el,
            answer: el?.answer,
          };
        }),
      };
      const response = await SystemService.setConfig(payload);
      if (response.config) {
        setSystemConfig(response.config);
        enqueueSnackbar("Updated Terms and conditions", {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  function handleRemove(sections, index, setFieldValue) {
    let returnData = sections.filter((section, id) => id !== index);
    setFieldValue("sections", returnData);
  }

  return (
    <>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <Container>
        <Formik
          initialValues={{
            sections: systemConfig.tnc || [{ question: "", answer: "" }],
          }}
          validateOnBlur
          enableReinitialize
          validationSchema={congurationEditorSchema}
          validateOnChange
          onSubmit={onFormSubmit}
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              {values?.sections?.map((section, sec_index) => (
                <Section key={sec_index}>
                  <Width>
                    <Row>
                      <H2>
                        {_.upperCase(labels.item)} {sec_index + 1}
                      </H2>
                      {values.sections.length > 1 && (
                        <img
                          onClick={() =>
                            handleRemove(
                              values.sections,
                              sec_index,
                              setFieldValue
                            )
                          }
                          src={require("../../../assets/images/removeIcon.svg")}
                          alt="remove"
                          style={{
                            marginRight: 5,
                            marginBottom: 16,
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </Row>
                    <FormLabel
                      style={{
                        fontFamily: theme.fonts.primaryFontSemiBold,
                        fontSize: 10,
                        color: COLORS.INPUT_LABEL,
                      }}
                    >
                      {_.upperCase(labels.question)}
                    </FormLabel>
                    <CustomInputField
                      className={"input-white"}
                      autoComplete={"new-password"}
                      type={"text"}
                      value={section.question}
                      error={
                        errors.sections?.length &&
                        touched.sections?.length &&
                        errors?.sections[sec_index]?.question &&
                        touched?.sections[sec_index]?.question
                      }
                      helperText={
                        errors.sections?.length &&
                        touched.sections?.length &&
                        touched?.sections[sec_index]?.question &&
                        errors?.sections[sec_index]?.question
                      }
                      id={`sections[${sec_index}].question`}
                      name={`sections[${sec_index}].question`}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ marginTop: 6, marginBottom: 20 }}
                    />
                    <FormLabel
                      style={{
                        fontFamily: theme.fonts.primaryFontSemiBold,
                        fontSize: 10,
                        color: COLORS.INPUT_LABEL,
                      }}
                    >
                      {_.upperCase(labels.answer)}
                    </FormLabel>

                    <Relative>
                      <Field name={`sections[${sec_index}].answer`}>
                        {({ field }) => (
                          <ReactQuillEditor
                            value={section?.answer}
                            onChange={field?.onChange(field.name)}
                          />
                        )}
                      </Field>
                      <HelperText style={{ position: "initial" }}>
                        {errors.sections?.length &&
                          touched.sections?.length &&
                          touched?.sections[sec_index]?.answer &&
                          errors?.sections[sec_index]?.answer}
                      </HelperText>
                    </Relative>
                  </Width>
                </Section>
              ))}
              <WrapperButtons>
                <HyperLink
                  style={{ marginTop: 15, marginBottom: 37 }}
                  onClick={() => addSection(setFieldValue, values.sections)}
                >
                  {labels.add_item}
                </HyperLink>
              </WrapperButtons>
              <div style={{ width: 349, height: 48 }}>
                <PrimaryCTAButton onClick={handleSubmit}>
                  {labels.save_changes}
                </PrimaryCTAButton>
              </div>
            </form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default Index;

const Container = styled.div`
  padding: 42px 46px;
  display: flex;
  flex-direction: column;
`;
const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  margin-bottom: 16px;
`;
const Section = styled.div`
  margin-top: 7px;
`;
const Width = styled.div`
  width: 100%;
`;
const WrapperButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Relative = styled.div`
  position: relative;
`;

const HelperText = styled.div`
  left: 3px;
  bottom: -12px;
  position: absolute;
  font-size: 10px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
