import React, { useState, memo, useEffect } from "react";
import styled from "styled-components";
import theme from "../../../../assets/theme";
import COLORS from "../../../../assets/Colors";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ActionBar from "../../../common/ActionBar";
import { navigate, useLocation } from "@reach/router";
import _ from "lodash";
import { PrimaryCTAButton, SecondaryCTAButton } from "../../../common/Buttons";
import { CustomInputField } from "../../../common/FormInputs";
import { Formik } from "formik";
import useLoader from "../../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import SystemService from "../../../../services/SystemService";
import { getErrorMessage } from "../../../../helpers/functions";

const useStyles = makeStyles({
  table: {
    backgroundColor: "transparent",
    borderRadius: 7,
    "& .MuiTableCell-root": {
      borderBottom: 0,
    },
  },
  tableHead: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.INPUT_LABEL,
    fontSize: 10,
    paddingBottom: 0,
  },
  row: {
    borderBottom: `1px solid ${COLORS.INPUT_BORDER}`,
  },
  radioGroup: {
    "& .MuiRadio-colorPrimary.Mui-checked": {
      color: COLORS.BTN_GREEN,
    },
  },
  formLabel: {
    fontFamily: theme.fonts.primaryFontRegular,
    color: COLORS.COLOR_DARK,
    marginRight: 5,
    // minWidth: 98,
  },
  radioButton: {
    color: COLORS.BTN_GREEN,
  },
});

const ExtraFields = () => {
  const classes = useStyles();
  const [extraFields, setExtraFields] = useState([]);
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const initialState = {
    label: "",
    value: "",
  };
  const location = useLocation();

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "configurations":
        navigate("/dashboard/configurations");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (location?.state?.noticeGenerationFields?.length) {
      setExtraFields(location?.state?.noticeGenerationFields);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addItems = (values, setFieldValue) => {
    const new_items = [
      ...values?.extra_fields_items,
      {
        label: "",
        value: "",
      },
    ];
    setFieldValue("extra_fields_items", new_items);
  };

  const deleteItems = (index, values, setFieldValue) => {
    if (values?.extra_fields_items?.length) {
      const filtered = values?.extra_fields_items.filter(
        (v, idx) => idx !== index,
      );
      setFieldValue("extra_fields_items", filtered);
    }
  };

  const InputField = memo(({ key, name, onChange, onBlur, value }) => {
    return (
      <CustomInputField
        className={"input-white"}
        variant="outlined"
        key={key}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  });

  async function onFormSubmit(payload) {
    try {
      setLoader({ state: true, message: "Updating Configuration..." });
      const response = await SystemService.setConfig(payload);
      if (response.config) {
        setExtraFields(response?.config?.noticeGenerationFields);
        enqueueSnackbar("Extra fields updated successfully", {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  return (
    <OuterContainer>
      <ActionBar
        breadcrumbs={["Configurations", "Extra Fields"]}
        {...{ onBreadcrumbClick }}
      />
      <Container>
        <Formik
          initialValues={{
            extra_fields_items: extraFields?.length
              ? extraFields?.map((item) => ({
                  ...item,
                  value: item?.value,
                  label: item?.label,
                }))
              : [initialState] || [initialState],
          }}
          onSubmit={({ extra_fields_items }) =>
            onFormSubmit({
              noticeGenerationFields: extra_fields_items.filter(
                (item) => item?.value && item?.label,
              ),
            })
          }
          // onSubmit={onFormSubmit}
          enableReinitialize
          validateOnBlur
          validateOnChange
          validateOnMount
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            setFieldError,
            setFieldValue,
            handleBlur,
            isValid,
            setFieldTouched,
          }) => (
            <FormContainer className="material-table" onSubmit={handleSubmit}>
              <TableContainer>
                <TableWrapper>
                  <BigTable>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              width: 53,
                            }}
                            className={classes.tableHead}
                          >
                            Extra Field Label
                          </TableCell>
                          <TableCell
                            style={{
                              width: 53,
                            }}
                            className={classes.tableHead}
                          >
                            Extra Fields
                          </TableCell>
                          <TableCell
                            style={{ width: 10 }}
                            className={classes.tableHead}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values?.extra_fields_items?.map((item, index) => (
                          <TableRow className={"table-row"} key={index}>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ position: "relative" }}
                            >
                              <InputField
                                key={index}
                                className={"input-white"}
                                value={item.label}
                                name={`extra_fields_items[${index}].label`}
                                onChange={handleChange}
                                variant="outlined"
                                onBlur={handleBlur}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ position: "relative" }}
                            >
                              <InputField
                                key={index}
                                className={"input-white"}
                                value={item.value}
                                name={`extra_fields_items[${index}].value`}
                                onChange={handleChange}
                                variant="outlined"
                                onBlur={handleBlur}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ position: "relative" }}
                            >
                              {values.extra_fields_items.length > 1 && (
                                <DeleteIcon
                                  onClick={() =>
                                    deleteItems(index, values, setFieldValue)
                                  }
                                  src={require("../../../../assets/images/removeIcon.svg")}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </BigTable>
                  <ButtonWrapper>
                    <SecondaryCTAButton
                      onClick={() => addItems(values, setFieldValue)}
                      style={{ paddingLeft: 0 }}
                    >
                      + Add Extra Fields
                    </SecondaryCTAButton>
                  </ButtonWrapper>
                </TableWrapper>
              </TableContainer>
              <FormControl>
                <Row>
                  <PrimaryCTAButton
                    onClick={handleSubmit}
                    style={{ width: "47%", height: 35 }}
                  >
                    Submit
                  </PrimaryCTAButton>
                </Row>
              </FormControl>
            </FormContainer>
          )}
        </Formik>
      </Container>
    </OuterContainer>
  );
};

export default ExtraFields;

export const OuterContainer = styled.div`
  border-radius: 10px;
  background-color: ${COLORS.PRIMARY_WHITE};
  margin: 40px 15px;
  padding-top: 1px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
    padding-top: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const Container = styled.div`
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  & .delete {
    display: flex;
    cursor: pointer;
  }
  @media ${theme?.breakpoints?.sm_up} {
    padding: 34px 43px;
  }
`;

export const FormContainer = styled.form`
  /* width: 907px; */

  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${theme?.breakpoints?.md_up} {
    width: auto;
  }
`;

export const TableContainer = styled.div`
  margin-top: 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 37px 25px 27px;
    border-radius: 5px;
    border: solid 0.5px ${COLORS.INPUT_BORDER};
  }
`;

export const FormControl = styled.div`
  margin-bottom: 13px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 436px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const CheckBoxLabel = styled.span`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  cursor: pointer;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TableWrapper = styled.div`
  @media ${theme?.breakpoints?.md_up} {
    background-color: #f9f9f9;
    border-radius: 7px;
  }
`;

export const ButtonWrapper = styled.div`
  width: fit-content;
  @media ${theme?.breakpoints?.md_up} {
    justify-content: flex-start;
    display: flex;
    width: 200px;
    padding-bottom: 20px;
    padding-top: 7px;
  }
`;

export const DeleteIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
`;

export const BigTable = styled.div`
  display: none;
  @media ${theme?.breakpoints?.md_up} {
    display: block;
  }
`;
