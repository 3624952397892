import React, { useState, useEffect } from "react";
import ActionBar from "../../common/ActionBar";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { navigate } from "@reach/router";
import _ from "lodash";
import { Formik } from "formik";
import { HyperLink } from "../../../styles/component/style";
import { PrimaryCTAButton } from "../../common/Buttons";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import SystemService from "../../../services/SystemService";
import { getErrorMessage, getFileName } from "../../../helpers/functions";
import { FormLabel } from "@material-ui/core";
import { CustomInputField, CustomFileUpload } from "../../common/FormInputs";
import labels from "../../../helpers/labels.json";
import AWSService from "../../../services/AWSService";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiAccordionSummary-content": {
      margin: "27px 0 27px 24px !important",
    },
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff",
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Index = () => {
  const breadcrumbs = [
    _.startCase(labels.configurations),
    labels.update_mediators_info,
  ];
  const { setLoader } = useLoader();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [systemConfig, setSystemConfig] = useState({
    mobile: "",
    tutorialUrl: "",
    faq: [],
    terminology: [],
    tnc: [],
    mailBodyContent: [],
    mediatorsInfo: [],
  });
  useEffect(() => {
    async function getSystemConfig() {
      try {
        setLoader({ state: true, message: "Fetching Configurations..." });
        const response = await SystemService.getConfigAuth();
        if (response.systemConfig) {
          setSystemConfig(response.systemConfig);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    getSystemConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function s3Upload(file, field, setFieldValue, setFieldError) {
    if (file.type.includes("image/")) {
      try {
        setLoader({ state: true, message: `Uploading file: ${file.name} ...` });
        const payload = {
          key: file.name,
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
          enqueueSnackbar("Successfully Uploaded", {
            variant: "success",
          });
        }
      } catch (error) {
        setFieldError(field, "Could not upload this file");
      } finally {
        setLoader({ state: false });
      }
    } else {
      return enqueueSnackbar("Invalid File Type", {
        variant: "error",
      });
    }
  }

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "configurations":
        navigate("/dashboard/configurations");
        break;
      default:
        break;
    }
  };

  function addSection(setFieldValue, sections) {
    let newSections = [
      ...sections,
      {
        _id: "",
        name: "",
        image: "",
        qualification: "",
        lastHeldPosition: "",
        yearsOfExperience: "",
        languagesKnown: "",
        affiliatedOrganisation: "",
        areasOfExpertise: "",
        category: "",
        location: "",
        chronicles: "",
        otherInterests: "",
      },
    ];
    setFieldValue("sections", newSections);
  }

  async function onFormSubmit(values, res) {
    try {
      setLoader({ state: true, message: "Updating Mediators Info..." });
      const payload = {
        ...systemConfig,
        mediatorsInfo: values.sections?.map((el) => {
          return {
            ...el,
            otherInterests: el?.otherInterests,
            chronicles: el?.chronicles,
            location: el?.location,
            category: el?.category,
            languagesKnown: el?.languagesKnown
              ?.toString()
              .split(",")
              .map((item) => item.trim()),
            areasOfExpertise: el?.areasOfExpertise,
            affiliatedOrganisation: el?.affiliatedOrganisation,
            yearsOfExperience: el?.yearsOfExperience,
            lastHeldPosition: el?.lastHeldPosition,
            qualification: el?.qualification,
            name: el?.name,
            _id: el?._id,
          };
        }),
      };
      const response = await SystemService.setConfig(payload);
      if (response.config) {
        setSystemConfig(response.config);
        enqueueSnackbar("Updated Mediators Info", {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  function handleRemove(sections, index, setFieldValue) {
    let returnData = sections.filter((section, id) => id !== index);
    setFieldValue("sections", returnData);
  }

  return (
    <>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <Container>
        <Formik
          initialValues={{
            sections: systemConfig.mediatorsInfo || [
              {
                _id: "",
                name: "",
                image: "",
                qualification: "",
                lastHeldPosition: "",
                yearsOfExperience: "",
                languagesKnown: "",
                affiliatedOrganisation: "",
                areasOfExpertise: "",
                category: "",
                location: "",
                chronicles: "",
                otherInterests: "",
              },
            ],
          }}
          validateOnBlur
          enableReinitialize
          validateOnChange
          onSubmit={onFormSubmit}
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
            setFieldError,
          }) => (
            <form onSubmit={handleSubmit}>
              {values.sections.map((section, sec_index) => (
                <Accordion className={classes.root}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <AccordionHeading>
                      {section.name} ({section._id})
                    </AccordionHeading>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Section key={sec_index}>
                      <Width style={{ paddingLeft: "42px" }}>
                        <Row>
                          <H2>
                            {_.upperCase(labels.mediator_item)} {sec_index + 1}
                          </H2>
                          {values.sections.length > 1 && (
                            <img
                              onClick={() =>
                                handleRemove(
                                  values.sections,
                                  sec_index,
                                  setFieldValue
                                )
                              }
                              src={require("../../../assets/images/removeIcon.svg")}
                              alt="remove"
                              style={{
                                marginRight: 5,
                                marginBottom: 16,
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </Row>
                        <div
                          type={"text"}
                          value={(section._id = [sec_index + 1])}
                          id={`sections[${sec_index}]._id`}
                          variant="outlined"
                          name={`sections[${sec_index}]._id`}
                          onChange={(value) =>
                            handleChange(
                              value,
                              `sections[${sec_index}]._id`,
                              setFieldValue,
                              setFieldError
                            )
                          }
                        />
                        {/* <FormLabel
                      style={{
                        fontFamily: theme.fonts.primaryFontSemiBold,
                        fontSize: 10,
                        color: COLORS.INPUT_LABEL,
                      }}
                    >
                      {_.upperCase(labels._id)}
                    </FormLabel> */}

                        {/* <CustomInputField
                      className={"input-white"}
                      autoComplete={"new-password"}
                      type={"text"}
                      value={section._id=[sec_index + 1]}
                      error={
                        errors.sections?.length &&
                        touched.sections?.length &&
                        errors?.sections[sec_index]?._id &&
                        touched?.sections[sec_index]?._id
                      }
                      id={`sections[${sec_index}]._id`}
                      variant="outlined"
                      name={`sections[${sec_index}]._id`}
                      // onChange={handleChange}
                      onChange={(value) =>
                        handleChange(
                          value,
                          `sections[${sec_index}]._id`,
                          setFieldValue,
                          setFieldError
                        )
                      }
                      onBlur={handleBlur}
                      style={{ marginTop: 6, marginBottom: 20 }}
                    /> */}
                        <FormLabel
                          style={{
                            fontFamily: theme.fonts.primaryFontSemiBold,
                            fontSize: 10,
                            color: COLORS.INPUT_LABEL,
                          }}
                        >
                          {_.upperCase(labels.neutral_name)}
                        </FormLabel>

                        <Relative>
                          <CustomInputField
                            className={"input-white"}
                            rowsMin={5}
                            autoComplete={"new-password"}
                            type={"text"}
                            value={section.name}
                            error={
                              errors.sections?.length &&
                              touched.sections?.length &&
                              errors?.sections[sec_index]?.name &&
                              touched?.sections[sec_index]?.name
                            }
                            id={`sections[${sec_index}].name`}
                            variant="outlined"
                            name={`sections[${sec_index}].name`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ marginTop: 6, marginBottom: 20 }}
                          />

                          <FormLabel
                            style={{
                              fontFamily: theme.fonts.primaryFontSemiBold,
                              fontSize: 10,
                              color: COLORS.INPUT_LABEL,
                            }}
                          >
                            {_.upperCase(labels.neutral_image)}
                          </FormLabel>

                          <CustomFileUpload
                            type="file"
                            value={section.image}
                            key={`${values.image}`}
                            file={{ name: getFileName(section.image) }}
                            error={
                              errors.sections?.length &&
                              touched.sections?.length &&
                              errors?.sections[sec_index]?.image &&
                              touched?.sections[sec_index]?.image
                            }
                            setFile={(file) =>
                              s3Upload(
                                file,
                                `sections[${sec_index}].image`,
                                setFieldValue,
                                setFieldError
                              )
                            }
                            accept="image/*"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ marginTop: 6, marginBottom: 20 }}
                          />

                          <FormLabel
                            style={{
                              fontFamily: theme.fonts.primaryFontSemiBold,
                              fontSize: 10,
                              color: COLORS.INPUT_LABEL,
                            }}
                          >
                            {_.upperCase(labels.neutral_qualification)}
                          </FormLabel>

                          <CustomInputField
                            className={"input-white"}
                            rowsMin={5}
                            autoComplete={"new-password"}
                            type={"text"}
                            value={section.qualification}
                            error={
                              errors.sections?.length &&
                              touched.sections?.length &&
                              errors?.sections[sec_index]?.qualification &&
                              touched?.sections[sec_index]?.qualification
                            }
                            id={`sections[${sec_index}].qualification`}
                            variant="outlined"
                            name={`sections[${sec_index}].qualification`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ marginTop: 6, marginBottom: 20 }}
                          />

                          <FormLabel
                            style={{
                              fontFamily: theme.fonts.primaryFontSemiBold,
                              fontSize: 10,
                              color: COLORS.INPUT_LABEL,
                            }}
                          >
                            {_.upperCase(labels.neutral_lastHeldPosition)}
                          </FormLabel>

                          <CustomInputField
                            className={"input-white"}
                            rowsMin={5}
                            autoComplete={"new-password"}
                            type={"text"}
                            value={section.lastHeldPosition}
                            error={
                              errors.sections?.length &&
                              touched.sections?.length &&
                              errors?.sections[sec_index]?.lastHeldPosition &&
                              touched?.sections[sec_index]?.lastHeldPosition
                            }
                            id={`sections[${sec_index}].lastHeldPosition`}
                            variant="outlined"
                            name={`sections[${sec_index}].lastHeldPosition`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ marginTop: 6, marginBottom: 20 }}
                          />

                          <FormLabel
                            style={{
                              fontFamily: theme.fonts.primaryFontSemiBold,
                              fontSize: 10,
                              color: COLORS.INPUT_LABEL,
                            }}
                          >
                            {_.upperCase(labels.neutral_yearsOfExperience)}
                          </FormLabel>

                          <CustomInputField
                            className={"input-white"}
                            rowsMin={5}
                            autoComplete={"new-password"}
                            type={"text"}
                            value={section.yearsOfExperience}
                            error={
                              errors.sections?.length &&
                              touched.sections?.length &&
                              errors?.sections[sec_index]?.yearsOfExperience &&
                              touched?.sections[sec_index]?.yearsOfExperience
                            }
                            id={`sections[${sec_index}].yearsOfExperience`}
                            variant="outlined"
                            name={`sections[${sec_index}].yearsOfExperience`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ marginTop: 6, marginBottom: 20 }}
                          />

                          <FormLabel
                            style={{
                              fontFamily: theme.fonts.primaryFontSemiBold,
                              fontSize: 10,
                              color: COLORS.INPUT_LABEL,
                            }}
                          >
                            {_.upperCase(labels.neutral_languagesKnown)}
                          </FormLabel>

                          <CustomInputField
                            className={"input-white"}
                            rowsMin={5}
                            autoComplete={"new-password"}
                            type={"text"}
                            value={section.languagesKnown}
                            error={
                              errors.sections?.length &&
                              touched.sections?.length &&
                              errors?.sections[sec_index]?.languagesKnown &&
                              touched?.sections[sec_index]?.languagesKnown
                            }
                            id={`sections[${sec_index}].languagesKnown`}
                            variant="outlined"
                            name={`sections[${sec_index}].languagesKnown`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ marginTop: 6, marginBottom: 20 }}
                          />

                          <FormLabel
                            style={{
                              fontFamily: theme.fonts.primaryFontSemiBold,
                              fontSize: 10,
                              color: COLORS.INPUT_LABEL,
                            }}
                          >
                            {_.upperCase(labels.neutral_affiliatedOrganisation)}
                          </FormLabel>

                          <CustomInputField
                            className={"input-white"}
                            rowsMin={5}
                            autoComplete={"new-password"}
                            type={"text"}
                            value={section.affiliatedOrganisation}
                            error={
                              errors.sections?.length &&
                              touched.sections?.length &&
                              errors?.sections[sec_index]
                                ?.affiliatedOrganisation &&
                              touched?.sections[sec_index]
                                ?.affiliatedOrganisation
                            }
                            id={`sections[${sec_index}].affiliatedOrganisation`}
                            variant="outlined"
                            name={`sections[${sec_index}].affiliatedOrganisation`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ marginTop: 6, marginBottom: 20 }}
                          />
                          <FormLabel
                            style={{
                              fontFamily: theme.fonts.primaryFontSemiBold,
                              fontSize: 10,
                              color: COLORS.INPUT_LABEL,
                            }}
                          >
                            {_.upperCase(labels.neutral_areasOfExpertise)}
                          </FormLabel>

                          <CustomInputField
                            className={"input-white"}
                            rowsMin={5}
                            autoComplete={"new-password"}
                            type={"text"}
                            value={section.areasOfExpertise}
                            error={
                              errors.sections?.length &&
                              touched.sections?.length &&
                              errors?.sections[sec_index]?.areasOfExpertise &&
                              touched?.sections[sec_index]?.areasOfExpertise
                            }
                            id={`sections[${sec_index}].areasOfExpertise`}
                            variant="outlined"
                            name={`sections[${sec_index}].areasOfExpertise`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ marginTop: 6, marginBottom: 20 }}
                          />
                          <FormLabel
                            style={{
                              fontFamily: theme.fonts.primaryFontSemiBold,
                              fontSize: 10,
                              color: COLORS.INPUT_LABEL,
                            }}
                          >
                            {_.upperCase(labels.neutral_category)}
                          </FormLabel>

                          <CustomInputField
                            className={"input-white"}
                            rowsMin={5}
                            autoComplete={"new-password"}
                            type={"text"}
                            value={section.category}
                            error={
                              errors.sections?.length &&
                              touched.sections?.length &&
                              errors?.sections[sec_index]?.category &&
                              touched?.sections[sec_index]?.category
                            }
                            id={`sections[${sec_index}].category`}
                            variant="outlined"
                            name={`sections[${sec_index}].category`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ marginTop: 6, marginBottom: 20 }}
                          />

                          <FormLabel
                            style={{
                              fontFamily: theme.fonts.primaryFontSemiBold,
                              fontSize: 10,
                              color: COLORS.INPUT_LABEL,
                            }}
                          >
                            {_.upperCase(labels.neutral_location)}
                          </FormLabel>

                          <CustomInputField
                            className={"input-white"}
                            rowsMin={5}
                            autoComplete={"new-password"}
                            type={"text"}
                            value={section.location}
                            error={
                              errors.sections?.length &&
                              touched.sections?.length &&
                              errors?.sections[sec_index]?.location &&
                              touched?.sections[sec_index]?.location
                            }
                            id={`sections[${sec_index}].location`}
                            variant="outlined"
                            name={`sections[${sec_index}].location`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ marginTop: 6, marginBottom: 20 }}
                          />

                          <FormLabel
                            style={{
                              fontFamily: theme.fonts.primaryFontSemiBold,
                              fontSize: 10,
                              color: COLORS.INPUT_LABEL,
                            }}
                          >
                            {_.upperCase(labels.neutral_chronicles)}
                          </FormLabel>

                          <CustomInputField
                            className={"input-white"}
                            rowsMin={5}
                            autoComplete={"new-password"}
                            type={"text"}
                            value={section.chronicles}
                            error={
                              errors.sections?.length &&
                              touched.sections?.length &&
                              errors?.sections[sec_index]?.chronicles &&
                              touched?.sections[sec_index]?.chronicles
                            }
                            id={`sections[${sec_index}].chronicles`}
                            variant="outlined"
                            name={`sections[${sec_index}].chronicles`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ marginTop: 6, marginBottom: 20 }}
                          />

                          <FormLabel
                            style={{
                              fontFamily: theme.fonts.primaryFontSemiBold,
                              fontSize: 10,
                              color: COLORS.INPUT_LABEL,
                            }}
                          >
                            {_.upperCase(labels.neutral_otherInterests)}
                          </FormLabel>

                          <CustomInputField
                            className={"input-white"}
                            rowsMin={5}
                            autoComplete={"new-password"}
                            type={"text"}
                            value={section.otherInterests}
                            error={
                              errors.sections?.length &&
                              touched.sections?.length &&
                              errors?.sections[sec_index]?.otherInterests &&
                              touched?.sections[sec_index]?.otherInterests
                            }
                            id={`sections[${sec_index}].otherInterests`}
                            variant="outlined"
                            name={`sections[${sec_index}].otherInterests`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ marginTop: 6, marginBottom: 20 }}
                          />
                        </Relative>
                      </Width>
                    </Section>
                  </AccordionDetails>
                </Accordion>
              ))}
              {console.log(errors)}
              <WrapperButtons>
                <HyperLink
                  style={{ marginTop: 10, marginBottom: 10 }}
                  onClick={() => addSection(setFieldValue, values.sections)}
                >
                  <MediatorCard>
                    <div className="add_circle">
                      <AddIcon className="add_icon" />
                    </div>
                    <Column>
                      <Flex className="flex-1">
                        <div
                          className="add_mediator flex flex-1"
                          style={{ padding: 15 }}
                        >
                          {labels.add_mediator}
                        </div>
                      </Flex>
                    </Column>
                  </MediatorCard>
                  {/* {labels.add_mediator} */}
                </HyperLink>
              </WrapperButtons>
              <div style={{ width: 349, height: 48 }}>
                <PrimaryCTAButton onClick={handleSubmit}>
                  {labels.save_changes}
                </PrimaryCTAButton>
              </div>
            </form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default Index;

const Container = styled.div`
  padding: 42px 46px;
  display: flex;
  flex-direction: column;
`;
const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  margin-bottom: 16px;
`;
const Section = styled.div`
  margin-top: 7px;
`;

const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

const Width = styled.div`
  width: 558px;
`;

const WrapperButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Relative = styled.div`
  position: relative;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MediatorCard = styled.div`
  // width: 295px;
  // height: 77px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 18px 16px;
  position: relative;
  & .add_mediator {
    color: ${COLORS.BTN_GREEN};
    font-family: ${theme?.fonts?.primaryFontSemiBold};
  }
  & .add_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    background-color: rgba(0, 131, 140, 0.13);
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;
